import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/guards/auth.guard';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
    {
        canActivate: [AuthGuard],
        path: '',
        component: FullComponent,
        children: [
            { path: '', redirectTo: '/starter', pathMatch: 'full' },
            {
                path: 'starter',
                loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule)
            },
            {
                path: 'collaborateur', 
                loadChildren: () => import('./collaborateur/collaborateur-list/collaborateur.module').then(m => m.CollaborateurModule)
            },
            {
                path: 'collaborateur/create',
                loadChildren: () => import('./collaborateur/collaborateur-create/collaborateur-create.module').then(m => m.CollaborateurCreateModule)
            },
            {
                path: 'collaborateur/detail',
                loadChildren: () => import('./collaborateur/collaborateur-detail/collaborateur-detail.module').then(m => m.CollaborateurDetailModule)
            },
            {
                path: 'user/create',
                loadChildren: () => import('./user/user-create/user-create.module').then(m => m.UserCreateModule)
            },
            {
                path: 'user/detail',
                loadChildren: () => import('./user/user-detail/user-detail.module').then(m => m.UserDetailModule)
            },
            {
                path: 'user',
                loadChildren: () => import('./user/user-list/user.module').then(m => m.UserModule)
            },
            {
                path: 'user-edit',
                loadChildren: () => import('./user/user-create/user-edit.module').then(m => m.UserEditModule)
            },
            {
                path: 'produit',
                loadChildren: () => import('./produit/produit-list/produit.module').then(m => m.ProduitModule)
            },
            {
                path: 'produit/create',
                loadChildren: () => import('./produit/produit-create/produit-create.module').then(m => m.ProduitCreateModule)
            },
            {
                path: 'produit/detail',
                loadChildren: () => import('./produit/produit-detail/produit-detail.module').then(m => m.ProduitDetailModule)
            },
            {
                path: 'produit/categorie',
                loadChildren: () => import('./produit/categorie/categorie-list/categorie-list.module').then(m => m.CategorieModule)
            },
            {
                path: 'produit/categorie/create',
                loadChildren: () => import('./produit/categorie/categorie-create/categorie-create.module').then(m => m.CategorieCreateModule)
            },
            {
                path: 'produit/categorie/detail',
                loadChildren: () => import('./produit/categorie/categorie-detail/categorie-detail.module').then(m => m.CategorieDetailModule)
            },
            {
                path: 'prospect',
                loadChildren: () => import('./prospect/prospect-list/prospect.module').then(m => m.ProspectModule)
            },
            {
                path: 'contrat',
                loadChildren: () => import('./contrat/contrat-list/contrat.module').then(m => m.ContratModule)
            },
            {
                path: 'contrat/create',
                loadChildren: () => import('./contrat/contrat-create/contrat-create.module').then(m => m.ContratCreateModule)
            },
            {
                path: 'prospect/create',
                loadChildren: () => import('./prospect/prospect-create/prospect-create.module').then(m => m.ProspectCreateModule)
            },
             {
                 path: 'reseau-list',
                 loadChildren: () => import('./reseau/reseau-list/reseau.module').then(m => m.ReseauModule)
             },
             {
                path: 'client',
                loadChildren: () => import('./client/client-list/client.module').then(m => m.ClientModule)
            },
             {
                path: 'reseau-create',
                loadChildren: () => import('./reseau/reseau-create/reseau-create.module').then(m => m.ReseauCreateModule)
            },
             {
                 path: 'equipe',
                 loadChildren: () => import('./equipe/equipe-list/equipe.module').then(m => m.EquipeModule)
             },
             {
                path: 'equipe/create',
                loadChildren: () => import('./equipe/equipe-create/equipe-create.module').then(m => m.EquipeCreateModule)
            },
             {
                path: 'sucursal',
                loadChildren: () => import('./succursale/succursale-list/succursale.module').then(m => m.SuccursaleModule)
            },
            {
                path: 'sucursal/create',
                loadChildren: () => import('./succursale/succursale-create/succursale-create.module').then(m => m.SuccursaleCreateModule)
            },
            {
                path: 'sucursal/detail',
                loadChildren: () => import('./succursale/succursale-detail/succursale-detail.module').then(m => m.SuccursaleDetailModule)
            },
            {
                path: 'poste',
                loadChildren: () => import('./poste/poste-list/poste.module').then(m => m.PosteModule)
            },
            {
                path: 'poste/create',
                loadChildren: () => import('./poste/poste-create/poste-create.module').then(m => m.PosteCreateModule)
            },
            {
                path: 'poste/detail',
                loadChildren: () => import('./poste/poste-detail/poste-detail.module').then(m => m.PosteDetailModule)
            },
            {
                path: 'prospect/detail',
                loadChildren: () => import('./prospect/prospect-detail/prospect-detail.module').then(m => m.ProspectDetailModule)
            },
            {
                path: 'succursale-objectif',
                loadChildren: () => import('./objectif/succursale/list/succursale-list.module').then(m => m.SuccursaleListObjectifModule)
            },
            {
                path: 'succursale-objectif/create',
                loadChildren: () => import('./objectif/succursale/create/succursale-create.module').then(m => m.SuccursaleObjectifCreateModule)
            },
            {
                path: 'reseau-objectif',
                loadChildren: () => import('./objectif/reseau/list/reseau-list.module').then(m => m.ReseauListObjectifModule)
            },
            {
                path: 'reseau-objectif/create',
                loadChildren: () => import('./objectif/reseau/create/reseau-create.module').then(m => m.ReseauObjectifCreateModule)
            },
            {
                path: 'equipe-objectif',
                loadChildren: () => import('./objectif/equipe/list/equipe-list.module').then(m => m.EquipeListObjectifModule)
            },
            {
                path: 'equipe-objectif/create',
                loadChildren: () => import('./objectif/equipe/create/equipe-create.module').then(m => m.EquipeObjectifCreateModule)
            },
            {
                path: 'objectif-collaborateur',
                loadChildren: () => import('./objectif/collaborateur/list/collaborateur-list.module').then(m => m.CollaborateurListObjectifModule)
            },
            {
                path: 'objectif-collaborateur/create',
                loadChildren: () => import('./objectif/collaborateur/create/collaborateur-create.module').then(m => m.CollaborateurObjectifCreateModule)
            },
            {
                path: 'equipe/detail',
                loadChildren: () => import('./equipe/equipe-detail/equipe-detail.module').then(m => m.EquipeDetailModule)
            },
            {
                path: 'reseau/detail', 
                loadChildren: () => import('./reseau/reseau-detail/reseau-detail.module').then(m => m.ReseauDetailModule)
            },
           
        ]
    },
    {
        path: '',
        component: BlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren:
                    () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
            }
        ]
    },
    {
        path: '**',
        redirectTo: '404'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes), NgbModule],
    exports: [RouterModule]
})
export class AppRoutingModule { } 
