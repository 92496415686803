import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { interval, Observable } from "rxjs";
import { environment, websocketMetas } from "src/environments/environment";
import { WebsocketAbstractClass } from "./websocketAbstractClass";

@Injectable({
  providedIn: 'root'
})
export class WebsocketService  extends WebsocketAbstractClass {
  unsubscribe() {
      this.subscription.unsubscribe();
  }
  


  constructor(private http: HttpClient,) {
      super();

      this.connect();
      //this.disconnect()
      this.ws.addEventListener('open',  (event) =>{
          console.log("WebSocket", event);
          if(localStorage.getItem("userId") != null){
            this.joinRoom(environment.realm, localStorage.getItem("userId"))
          }
         
      })

      this.ws.addEventListener('close', (event) => {
          console.log("Websocket disconnected");
          this.ws = new WebSocket('wss://'+environment.ws);
          if (this.ws.readyState == WebSocket.OPEN && localStorage.getItem("userId") != null)
             // this.joinRoom(localStorage.getItem("room"), localStorage.getItem("room"))
          this.joinRoom(environment.realm, localStorage.getItem("userId"))
          console.log(this.ws);

          this.addWsListener()
      })

      this.addWsListener()
  }

  addWsListener(){
      this.ws.addEventListener('message', e => {
          let data = JSON.parse(e.data)
          console.log("websocket message", data);

          super.notifyAll(data)
      })
  }

  connect() {
      // console.log(this.ws.readyState);
      // console.log(this.observers.length);

      if (this.ws.readyState == WebSocket.CLOSED) {
          try {
              this.ws = new WebSocket('wss://'+environment.ws);
              this.addWsListener()
              if (this.ws.readyState == WebSocket.OPEN && localStorage.getItem("userId") != null)
                  this.joinRoom(/*localStorage.getItem("room")*/environment.realm, localStorage.getItem("room"))
              else
                  this.connect()
          }
          catch (e) {
              console.error(e);

          }

      }
      else {
          try {
            //console.log(this.getConnected())
            if(this.getConnected() == 'true'){
                //this.joinRoom(localStorage.getItem("room"), localStorage.getItem("room"))
            }else{
                if(localStorage.getItem("userId") != null){
                    this.joinRoom(environment.realm, localStorage.getItem("userId"))
                console.log("joined", localStorage.getItem("connected"));
                this.setConnected(true)
                }
            }
              
          }
          catch (e) {
              console.error("erreur",e);

          }
      }
      //   this.setConnected(true);


  }

  disconnect() {
      this.ws.close();
      this.setConnected(false);
      console.log("Disconnected");
  }

  send(meta:any, room:any, msg:any, guid?:any) {
      console.log({ meta: meta, room: room, guid: guid, msg: msg });
      this.joinRoom(localStorage.getItemItem('room'), localStorage.getItemItem('guid'))

      this.ws.send(JSON.stringify({ meta: meta, room: room, guid: guid, msg: msg }))
  }

  sendMessage(meta:any, room:any, msg:any, guid?:any) {
      console.log({ meta: meta, room: room, guid: guid, msg: msg });
      this.joinRoom(localStorage.getItem("room"), localStorage.getItem("room"))

      this.ws.send(JSON.stringify({ meta: meta, room: room, guid: guid, msg: msg }))

  }

  setConnected(connected:any) {
      console.log("connected: ", connected)
      localStorage.setItem('connected', connected)
  }

  getConnected() : any{
      return localStorage.getItem('connected')
  }

  joinRoom(room:any, guid:any): void {
      this.ws.send(JSON.stringify({ meta: websocketMetas.join, room: room, guid: guid }))
  }

  leaveRoom(room:any, guid:any): void {
      this.ws.send(JSON.stringify({ meta: websocketMetas.leave, room: room, guid: guid }))
  }


  private addItem(type: string, item: any) {
      return this.http.post(type, item).pipe((data) => {
          this .connect()
          return data
      })

  }

  subscribe() {
    this.subscription = interval(1000) .subscribe(x => {
      // if (localStorage.getItem("room"))
           this.connect()
   });
}
}
