// side-menu.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  public menuVisible = new BehaviorSubject<boolean>(true);
  public menuVisibility = this.menuVisible.asObservable();

  constructor() { }

  toggleMenu() {
    this.menuVisible.next(!this.menuVisible.value);
    console.info(this.menuVisible.value)
  }
}