// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    apiUrl:"http://localhost:8080/api",
    oauthApiUrl:"https://oauth2.crm.id2real.net/api",
    staffCompaniesApi: "https://staff-companiesms.crm.id2real.net/api",
    productsApi: "https://productms.crm.id2real.net/api",
    operationsApi: "https://operations.crm.id2real.net/api",
    souscriptionApi: "https://souscription.crm.id2real.net/api",
    socket : "socket.crm.id2real.net",
    realm: "NSIACRM",
    ws : "socket.crm.id2real.net",
  };
  export const websocketMetas = { join: "join", leave: "leave", notificate: 'notificate', specificNotificate: 'specific-notificate', exchangeData: "exchangeData" };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
