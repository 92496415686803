import { Component, AfterViewInit, OnInit, EventEmitter, Output, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROLE_ADVISOR_MENU, ROLE_MANAGER_MENU, ROLE_MARKETING_MENU, ROLE_SUPER_ADMIN_MENU, ROLE_SUPPORT_MENU } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Admin, User } from '../../../models/all-interfaces';
import { AuthService } from '../../../services/auth.service';
import { environment } from '../../../environments/environment';
import { SideMenuService } from 'src/services/side-menu.service';
declare var $: any;

enum ScreenSize {
  Mobile,
  Tablet,
  Desktop
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  isVisible: boolean = false;

  user: Admin;
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];

  role_manager_menu: RouteInfo[];
  role_super_admin_menu: RouteInfo[];
  role_advisor_menu: RouteInfo[];
  role_marketing_menu: RouteInfo[];
  role_creator_menu: RouteInfo[];
  role_support_menu: RouteInfo[];

  screenWidth: ScreenSize;

  envUrl: string = environment.apiUrl;
  // this is for the open close
  addExpandClass(element: any, sizes: any) {
    // this.showMenu = '0';
    //  alert(this.showMenu)
    //console.info(this.sidebarnavItems)
    if (element === this.showMenu) {
      // this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
    //this.showMenu = "starter"
    console.log(this.showMenu, sizes, "size", this.screenWidth, ScreenSize.Tablet)
    if (sizes == 0) {
      if (this.screenWidth === ScreenSize.Tablet) {
        this.closeMenu();
        //this.closeMenu();
      }
      // this.closeMenu();
    }

    

    //  alert(this.showMenu)
  }

  addActiveClass(element: any, sizes: any) {
    //console.info(sizes)
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
        this.closeMenu();
    } else {
      this.showSubMenu = element;

    }
   // console.info(element, "data");
    //this.closeMenu();
    if (this.screenWidth === ScreenSize.Tablet) {
      this.closeMenu();
      this.closeMenu();
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private sideMenuService: SideMenuService
  ) {
    this.role_manager_menu = ROLE_MANAGER_MENU;
    this.role_super_admin_menu = ROLE_SUPER_ADMIN_MENU;
    this.role_advisor_menu = ROLE_ADVISOR_MENU;
    this.role_marketing_menu = ROLE_MARKETING_MENU;
    this.role_creator_menu = ROLE_SUPER_ADMIN_MENU;
    this.role_support_menu = ROLE_SUPPORT_MENU;

  }
  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setCurrentScreenSize(event.target.innerWidth);
  }

  private setCurrentScreenSize(width: number) {
    if (width < 768) {
      this.screenWidth = ScreenSize.Mobile;
     // this.closeMenu();
    } else if (width >= 768 && width < 992) {
      this.screenWidth = ScreenSize.Tablet;
      //this.closeMenu();
    } else {
      this.screenWidth = ScreenSize.Desktop;
    }
    console.log(this.screenWidth,"screenWidth");
  }

  // End open close
  ngOnInit() {
    // this.screenWidth = window.innerWidth;
    this.setCurrentScreenSize(window.innerWidth);
    /* window.addEventListener('resize', () => {
       this.screenWidth = window.innerWidth;
       console.log('screenWidth', this.screenWidth);
     });*/

    // this.sidebarnavItems = ROLE_SUPER_ADMIN_MENU.filter(sidebarnavItem => sidebarnavItem);
    this.sideMenuService.menuVisibility.subscribe((visibility: boolean) => {
      this.isVisible = visibility;
    });
    if ((window as any)?.__NSIA_USER__ !== undefined) {

      //  this.authService.getWindowUser().subscribe(user => this.user = user);
      //  this.sidebarnavItems = this[this.user.roles[0].toLowerCase() + '_menu'].filter(sidebarnavItem => sidebarnavItem);
      this.sidebarnavItems = this["role_super_admin_menu"].filter(sidebarnavItem => sidebarnavItem);
    } else {
      this.sidebarnavItems = this["role_super_admin_menu"].filter(sidebarnavItem => sidebarnavItem);

      // this.authService.getApiUserInfo().subscribe((value) => {
      // this.user = value;
      //console.info(value)
      // this.authService.updateWindowUser(this.user);
      // this.sidebarnavItems = this[value.user.roles[0].toLowerCase() + '_menu'].filter(sidebarnavItem => sidebarnavItem);
      //   });
    }
  }

  onLoggedOut(): void {
    this.authService.logout().subscribe(value => {
      if (value) {
        return this.router.navigateByUrl('authentication/login');
      }
    });
  }

  closeMenu() {
    this.sideMenuService.toggleMenu(); // Toggle the menu to close it
  }

  showProfile() {
    const navigatorExtra: NavigationExtras = {
      queryParams: {
        data: this.user,
        admin: true,
        provider: false
      },
    };
    this.router.navigateByUrl('pages/profile', navigatorExtra);

  }
}
