<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<!-- [ngClass]="
{
    'defaultdark': color == 'defaultdark',
    'greendark': color == 'greendark',
    'reddark': color == 'reddark',
    'bluedark': color == 'bluedark',
    'purpledark': color == 'purpledark',
    'megnadark': color == 'megnadark',
    'default': color == 'default',
    'green': color == 'green',
    'red': color == 'red',
    'blue': color == 'blue',
    'purple': color == 'purple',
    'megna': color == 'megna',
    'bline': color == 'bline',
    'blinedark': color == 'blinedark',

    'mini-sidebar': showMinisidebar,
    'dark-theme': showDarktheme,
    'rtl-theme': showRtl
}
"-->
<div id="main-wrapper" [ngClass]="
{
    'defaultdark': color == 'defaultdark',
    'greendark': color == 'greendark',
    'reddark': color == 'reddark',
    'bluedark': color == 'bluedark',
    'purpledark': color == 'purpledark',
    'megnadark': color == 'megnadark',
    'default': color == 'default',
    'green': color == 'green',
    'red': color == 'red',
    'blue': color == 'blue',
    'purple': color == 'purple',
    'megna': color == 'megna',
    'bline': color == 'bline',
    'blinedark': color == 'blinedark',

    'mini-sidebar': showMinisidebar,
    'dark-theme': showDarktheme,
    'rtl-theme': showRtl
}">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->

    <app-navigation class="w-100" (toggleSidebar)="toggleSidebar()" style="display: inline-block !important;"></app-navigation>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
   <div id="container-row" >
    <aside  class="left-sidebar" [ngClass]="{'lhidden': !sideMenuService.menuVisible.value}">
        <!-- Sidebar scroll-->
        <div class="scroll-sidebar" [perfectScrollbar]="config">
            <app-sidebar  (toggleSidebar)="toggleSidebar()" ></app-sidebar>
        </div>
        <!-- End Sidebar scroll-->
    </aside>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper" [ngClass]="{'full-width': isSidebarHidden}">
      <!--<app-breadcrumb></app-breadcrumb>-->  
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid" style="padding: 0px 15px 0px 15px !important;">

            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <router-outlet></router-outlet>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Setting Right sidebar -->
            <!-- ============================================================== -->
            <!--<button class="right-side-toggle btn-inverse btn btn-circle btn-lg" (click)="showSettings = !showSettings">
                <i class="ti-settings text-white fa-spin"></i>
            </button>-->
            
            <!-- ============================================================== -->
            <!-- End Right sidebar -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
    </div>
   </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
   
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->

</div>
