import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterEvent, RouterStateSnapshot} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.info('AuthGuard1');
    this.router.events.subscribe((routerData) => {
    
      if (routerData instanceof RouterEvent) {
        this.authService.setCurrentPath(routerData.url);
      }
    });
    if (this.authService.isLoggedIn() === true) {
      console.info('AuthGuard1');
      return true;
    } else {
      console.info('AuthGuardZ');
      this.router.navigate(['/authentication/login']);
    }
  }
}
