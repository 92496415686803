

<nav class="sidebar-nav">
  <ul id="">
    <!-- First level menu -->
    <!-- [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'" -->
    <li [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems"
      >
      <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{ sidebarnavItem.title
                 }}</div>
      <a [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null" [ngClass]="[sidebarnavItem.class]"
        *ngIf="!sidebarnavItem.extralink;" (click)="addExpandClass(sidebarnavItem.title,sidebarnavItem.submenu.length)">
        <i *ngIf="sidebarnavItem.image == null" [ngClass]="[sidebarnavItem.icon]"></i>
        <img *ngIf="sidebarnavItem.image != null" style="margin-right: 10px !important;"  [src]="[sidebarnavItem.image]" width="24px" height="24px" />
        <span class="hide-menu1"> {{ sidebarnavItem.title }}
          <span *ngIf="sidebarnavItem.label != '' "
            [ngClass]="[sidebarnavItem.labelClass]">{{sidebarnavItem.label}}</span>
        </span>
      </a>
      <!-- Second level menu -->
      <ul class="collapse" *ngIf="sidebarnavItem.submenu.length > 0"
        [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
        <li *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
          [class.active]="showSubMenu === sidebarnavSubItem.title"
          [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
          <a [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]"
            [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
            [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;"
            (click)="addActiveClass(sidebarnavSubItem.title,sidebarnavSubItem.submenu.length)">
            <i [ngClass]="[sidebarnavSubItem.icon]"></i>
            {{ sidebarnavSubItem.title  }}
          </a>
          <!-- Third level menu -->
          <ul class="collapse" *ngIf="sidebarnavSubItem.submenu.length > 0"
            [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
            <li *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu" routerLinkActive="active"
              [ngClass]="[sidebarnavSubsubItem.class]">
              <a [routerLink]="[sidebarnavSubsubItem.path]" *ngIf="!sidebarnavSubsubItem.extralink;"
                [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                {{ 'sidebar-menu.'+sidebarnavSubsubItem.title | translate }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</nav>
<nav class="bottom-div sidebar-nav">

  <ul id="sidebarnav1">
    <li>
      <a href="javascript:void(0)">
        <i class="fa fa-question-circle" style="color: black;"></i> FAQ
      </a></li>
    <li>
      <a href="javascript:void(0)">
        <i class="fa fa-cog" style="color: black;"></i> Paramètres
      </a>
    </li>

    <li (click)="toggleSidebar.emit()">
      <a class="nav-link sidebartoggler waves-effect waves-dark" href="javascript:void(0)"> Réduire le ménu &nbsp;&nbsp;
        <span class="icon right-icon">
          <i class="fa fa-angle-double-left" aria-hidden="true"></i>
        </span>
      </a>
    </li>

  </ul>
</nav>
<!-- End Sidebar navigation -->
