import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SideMenuService } from 'src/services/side-menu.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  color = 'bline';
  showSettings = false;
  showMinisidebar = false;
  showDarktheme = false;
  showRtl = false;
  isSidebarHidden : Boolean = false;

  public innerWidth: any;

  public config: PerfectScrollbarConfigInterface = {};
  @Input() visible : Boolean;

  constructor(public router: Router,public sideMenuService : SideMenuService) { }

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/dashboard1']);
    }
    this.handleLayout();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.handleLayout();
  }

  toggleSidebar() {
   //this.showMinisidebar = !this.showMinisidebar;
   this.sideMenuService.toggleMenu();
   this.isSidebarHidden = !this.isSidebarHidden;
   console.log(this.sideMenuService.menuVisible.value);
   
  }

  handleLayout() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.showMinisidebar = true;
    } else {
      this.showMinisidebar = false;
    }
  }
}
