import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";

export class AddObject {
    static readonly type = '[Zoo] Add Object';

    constructor(public data: any) {
        this.data = data;
    }
}

export interface ObjectStateModel {
    data: any;
}

@State<ObjectStateModel>({
    name: 'object',
    defaults: {
        data: []
    }
})

@Injectable()
export class ObjectState {
    @Action(AddObject)
    addAnimal(ctx: StateContext<ObjectStateModel>, action: AddObject) {
        console.warn(action,"ok");
        const state = ctx.getState();
        ctx.setState({
            ...state,
            data: action.data
        });
    }
}

