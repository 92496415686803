<header class="topbar">
  <nav class="d-flex top-navbar navbar-expand-md navbar-light">
   
    <!-- ============================================================== -->
    <!-- End Logo -->
    <!-- ============================================================== -->
    <div class="navbar-collapse">
      <!-- ============================================================== -->
      <!-- toggle and nav items -->
      <!-- ============================================================== -->

      <ul class="navbar-nav mr-auto mt-md-0" style="text-align: center !important;align-items: center;">
        <!-- This is  -->
        <li>
          <img src="assets/images/new/logo.png" style="width: 85px;margin-top:0px" alt="homepage"
            class="dark-logo rounded" />
        </li>
        <li (click)="toggleSidebar.emit()" class="nav-item nav-link pl-0 d-lg-none_">
          <a class="nav-link sidebartoggler waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"
              style="color: #000;"></i></a>
        </li>
        <ol class="breadcrumb mb-0  hidden-xs-down" style="background: white;">
          <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
            <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
              <a href='javascript:void(0)'>{{ url.title | translate }}</a>
            </li>
            <li class="breadcrumb-item active hidden-tablet" *ngIf="last">{{ url.title | translate }}</li>
          </ng-template>
        </ol>

       

      </ul>
      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
      <ul class="navbar-nav" style="text-align: center !important;align-items: center;">

        <li class=" hidden-sm-down">
          <div class="input-container">
            <div class="form-group" style="margin-bottom: 0px !important;">
              <span class="icon left-icon">
                <i class="ri-search-line"></i>
              </span>
              <input class="form-control" style="height: 50px;" type="text" placeholder="Rechercher ( Ctrl + K )">

            </div>

          </div>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link text-muted waves-effect waves-dark">
            <i class="ri-notification-2-line" style="color: black;"></i>
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link text-muted waves-effect waves-dark">
            <i class="ri-question-line" style="color: black;"></i>
          </a>
        </li>
        <li class="nav-item">
          <div class="" style="text-align: center;margin-right: 15px;"><span style="height: 250px; color: #EEF5F9;"><img
                src="assets/images/users/line.png" /></span></div>
        </li>
        <li>
          <div class="message-center" [perfectScrollbar]="config">
            <a href="#">
              <div class="user-img">
                <img *ngIf="userInfo && userInfo.collaborateur != null && userInfo.collaborateur.photo == null" src="assets/images/new/avatar_neutral.avif" alt="user" class="img-circle" width="40">
                <img *ngIf="userInfo && userInfo.collaborateur != null && userInfo.collaborateur.photo != null" src="{{userInfo.collaborateur.photo}}" alt="user" class="img-circle" width="40">
              </div>

            </a>
          </div>
        </li>
        <li class="nav-item nav-item dropdown" ngbDropdown style="background-color: white;">
          <div class="profile-text m-t-5 m-l-5">
            <a *ngIf="userInfo && userInfo.collaborateur != null" href="javascript:void(0)" ngbDropdownToggle class="nav-link">
              <h5 ><strong
                  style="color: black;font-family: 'Poppins' !important;font-style: normal !important;line-height: 118% !important;font-size: 16px !important;font-weight: 600 !important;">{{userInfo.collaborateur.nom}} {{userInfo.collaborateur.prenoms}}</strong>
              </h5>
              <h6 style="color: black;font-family: 'Poppins' !important;font-style: normal !important;line-height: 118% !important;font-size: 16px !important;font-weight: 300 !important;">{{userInfo.group.name}}</h6>
            </a>


          </div>
          <div class="dropdown-menu dropdown-menu-left mailbox" ngbDropdownMenu>
            <ul>
              <li class="li-drop">
                <div class="drop-title">Mon profil</div>
              </li>
              <li class="li-drop">
                <div class="drop-title">Mes paramètres</div>
              </li>
              <li class="li-drop">
                <div class="drop-title">Aide & FAQ</div>
              </li>
              <li>
                <div class="message-center" [perfectScrollbar]="config">

                </div>
              </li>
              <li (click)="logout()">
                <a class="nav-link logout-btn" href="javascript:void(0);">
                  Déconnexion
                </a>
              </li>
            </ul>
          </div>
        </li>

        <!-- ============================================================== -->
        <!-- End Messages -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Profile -->
        <!-- ============================================================== -->
        <!--<li class="nav-item dropdown" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                        href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="assets/images/users/1.jpg" alt="user" class="profile-pic" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-img">
                                        <img src="assets/images/users/1.jpg" alt="user">
                                    </div>
                                    <div class="u-text">
                                        <h4>Steave Jobs</h4>
                                        <p class="text-muted">varun@gmail.com</p>
                                        <a href="pages-profile.html" class="btn btn-rounded btn-danger btn-sm">View
                                            Profile</a>
                                    </div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li>
                                <a href="#">
                                    <i class="ti-user"></i> My Profile</a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="ti-wallet"></i> My Balance</a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="ti-email"></i> Inbox</a>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li>
                                <a href="#">
                                    <i class="ti-settings"></i> Account Setting</a>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li>
                                <a href="#">
                                    <i class="fa fa-power-off"></i> Logout</a>
                            </li>
                        </ul>
                    </div>
                </li>-->
      </ul>
    </div>
  </nav>
</header>
