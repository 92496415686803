import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Admin, Group, Role } from '../models/all-interfaces';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {catchError, map, mapTo, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Router, RouterEvent} from '@angular/router';
import {ALL_PATH} from '../app/shared/sidebar/menu-route-user';

@Injectable()
export class GroupService {
  private baseUrl: string = environment.oauthApiUrl + '/auth/'+environment.realm+'/login';

  private readonly JWT_TOKEN = 'NSIA_JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'NSIA_REFRESH_TOKEN';

  private readonly USER_INFO = 'NSIA_USER_INFO';

  private readonly  USER_PATH = 'NSIA_USER_PATH';
  private  readonly CURRENT_PATH = 'NSIA_CURRENT';

  private readonly LANG = 'NSIA_LANG';

  private userSource: BehaviorSubject<Admin>;

  constructor(private http: HttpClient, private translate: TranslateService, private router: Router) {
    this.userSource = new BehaviorSubject<Admin>((window as any).__NSIA_USER__);
  }

  private currentPath = '';

  updateCurrentPath(path: string) {
    this.currentPath = path;
  }

 /* login(user: {username: string, password: string}): Observable<boolean> {
    return this.http.post(this.baseUrl, user, {observe: 'response' as 'body', responseType: 'json'})
      .pipe(
        map((response: any) => {
           this.updateWindowUser(response.body.user);
        //  console.info(response)
          this.doLoginUser(response.body);
          return true;
        }),
        catchError(error => {
          console.info("erreur",error.error);
         // console.error(error.error);
          return of(false);
        }),
      );
  }*/

  getGroups() : Observable<Group[]>{
    return this.http.get<Group[]>(environment.oauthApiUrl + '/auth/group/'+environment.realm+'/getGroups');
  }



}
