import { Component } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { WebsocketAbstractClass } from 'src/socket/websocketAbstractClass';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private subscription: Subscription;
  title = 'application';

  constructor(private ws:WebsocketAbstractClass,){
    ws.addObserver(this)
    // this.subscription = interval(1000)
    // .subscribe(x => {
    //         this.ws.connect()
    // });
   
  }
  updateData(event?){
    console.log(event);
    if(event.code != null){
      this.processNotification(event)
    }
   // this.processNotification(event)
    if(event=="Poste")
      console.log(event);
  }
 processNotification(event){
  if(event.code != null && event.color == "success"){
    Swal.fire({
      icon: event.color == 'success' && event.code != null ? 'success' : 'error',
      title: event.typeEntity,
      text: event.message,
      toast: true,
      position: 'top-start', // Position of the toast (top-end, top-start, bottom-end, bottom-start)
      showConfirmButton: false, // Hide the "OK" button
      timer: 5000, // Duration of the toast in milliseconds (3 seconds in this case)
    });
  }else{
    Swal.fire({
      icon: event.color ,
      title: event.typeEntity,
      text: event.message,
      toast: true,
      position: 'top-start', // Position of the toast (top-end, top-start, bottom-end, bottom-start)
      showConfirmButton: true, // Hide the "OK" button
     // timer: 5000, // Duration of the toast in milliseconds (3 seconds in this case)
    });
  }
  
 }
  destroy() {
    this.ws.deleteObserver(this)
    this.subscription.unsubscribe();
  }

}
