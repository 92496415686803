//import { NotificationService } from '../services/notification.service';
import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    CommonModule,
    LocationStrategy,
    HashLocationStrategy
} from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NgbCalendarGregorian, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { AuthService } from '../services/auth.service';
/*import { AdminService } from '../services/admin.service';
import { ProfilService } from '../services/profil.service';
import { CustomerService } from '../services/customer.service';
import { ProviderService } from '../services/provider.service';*/
import { AuthGuard } from '../guards/auth.guard';
import { TokenInterceptor } from '../interceptors/token.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { RouteGuard } from '../guards/route.guard';
import { RoleService } from 'src/services/role.service';
import { GroupService } from 'src/services/group.service';
import { StaffService } from 'src/services/staff.service';
import { LoadingComponent } from './components/loadingModal/loading.component';
import { OptionsButtonModule } from './components/optionsComponent/optionsButton.module';
import { AddButtonModule } from './components/addButtonComponent/addButton.module';
import { LoadingModule } from './components/loadingModal/loading.component.module';
import { OptionsButtonComponent } from './components/optionsComponent/optionsButton.component';
import { AddButtonComponent } from './components/addButtonComponent/addButton.component';
import { OperationService } from 'src/services/operation.service';
import { ProduitService } from 'src/services/produit.service';
import { NgxsModule } from '@ngxs/store';
import { ZooState } from './store/feed.animal.action';
import {  ObjectState } from './store/object.action';
import { environment } from 'src/environments/environment';
import { FormatNumberPipe } from 'src/services/format-number.pipe';
import { ProspectCreateModule } from './prospect/prospect-create/prospect-create.module';
import { SouscriptionService } from 'src/services/souscription.service';
import { WebsocketAbstractClass } from 'src/socket/websocketAbstractClass';
import { WebsocketService } from 'src/socket/websocket.service';


/*import {NotificationPrixService} from '../services/notificationprix.service';
import {PubliciteService} from '../services/publicite.service';
import { AllComplaintsService } from '../services/allcomplaints.service';
import { PenalityService } from '../services/penality.service';*/


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};



@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
        FullComponent,
        BlankComponent,
        NavigationComponent,
        BreadcrumbComponent,
        SidebarComponent,
       
        
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        ToastrModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        
        PerfectScrollbarModule,
        AppRoutingModule,
        ReactiveFormsModule,
        NgxsModule.forRoot([ZooState,ObjectState],{
            developmentMode: !environment.production
          }),
      
    ],
    providers: [
        { provide: WebsocketAbstractClass, useClass: WebsocketService },
        AuthService,
        AuthGuard,
        RouteGuard,
        RoleService,
        GroupService,
        StaffService,
        OperationService,
        ProduitService,
        SouscriptionService,
        NgbCalendarGregorian,

       /* AdminService,
        ProfilService,
        PenalityService,
        NotificationPrixService,
        AllComplaintsService,
        PubliciteService,
        CustomerService,
        ProviderService,
        CountryService,
        DeviseService,
        TownService,
        FileToBase64,
        PaymentMethodService,
        CategoryService,
        UserService,
        TagService,
        ServiceService,
        BookingService,
        TicketService,
        TicketCategoryService,
        WarningMessageService,
        TransactionService,
        CurrencyConvertService,
        SpecificDocumentService,
        PaymentListService,
        CodePromoService,
        NotificationService,
        DiplomeService,*/
        { provide: LOCALE_ID, useValue: 'fr' },
        { provide: LOCALE_ID, useValue: 'de' },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
