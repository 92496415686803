<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="row page-titles">
    <div class="col-lg-3 col-md-4 col-xs-12 align-self-center">
        <h4 class="text-themecolor m-b-0">{{ pageInfo?.title | translate }}</h4>
    </div>
    <div class="col-lg-9 col-md-8 col-xs-12 align-self-center">
        <ol class="breadcrumb">
            <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
                <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                    <a href='javascript:void(0)'>{{ url.title | translate }}</a>
                </li>
                <li class="breadcrumb-item active" *ngIf="last">{{ url.title | translate }}</li>
            </ng-template>
        </ol>
    </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
