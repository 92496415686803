import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Admin, Group, Profil, Role, User, UserInfo } from '../models/all-interfaces';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {catchError, map, mapTo, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Router, RouterEvent} from '@angular/router';
import {ALL_PATH} from '../app/shared/sidebar/menu-route-user';

@Injectable()
export class AuthService {
  private baseUrl: string = environment.oauthApiUrl + '/auth/'+environment.realm+'/login';
  private addUserUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/addUser';
  private updateUserUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/updateUser';
  private addUserNewPasswordUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/newPassword';
  private addUserGroupUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/addGroup';
  private getGroupsByUserUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/getGroups';
  private getRolesByUserUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/getRoles';
  private deleteUserGroupUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/deleteGroup';
  private addUserRoleUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/addRole';
  private deleteUserRoleUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/deleteRoles';
  private listUsersUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/profil/getUsers';
  private userByEmailUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/getUsers/email';
  private userByUsernameUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/getUsers/username';
  private sendPasswordResetEmailUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/sendActionRequiredMail';
  private logoutUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/logout';
  private deleteUserUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/deleteUser';
  private getUserByIdUrl: string = environment.oauthApiUrl + '/auth/user/'+environment.realm+'/getUsers';

  private readonly JWT_TOKEN = 'NSIA_JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'NSIA_REFRESH_TOKEN';

  private readonly USER_INFO = 'NSIA_USER_INFO';

  private readonly  USER_PATH = 'NSIA_USER_PATH';
  private  readonly CURRENT_PATH = 'NSIA_CURRENT';

  private readonly LANG = 'NSIA_LANG';

  private userSource: BehaviorSubject<UserInfo>;

  constructor(private http: HttpClient, private translate: TranslateService, private router: Router) {
    this.userSource = new BehaviorSubject<UserInfo>((window as any).__NSIA_USER__);
  }

  private currentPath = '';

  updateCurrentPath(path: string) {
    this.currentPath = path;
  }

  addUser(user: any) : Observable<HttpResponse<any>>{
   return  this.http.post<any>(this.addUserUrl, user, {observe: 'response' as 'body', responseType: 'json'})
  }
  updateUser(user: any) : Observable<HttpResponse<any>>{
    return  this.http.put<any>(this.updateUserUrl, user, {observe: 'response' as 'body', responseType: 'json'})
   }
  addUserNewPassword(user: String, password: any): Observable<HttpResponse<any>>{
   return  this.http.put<any>(this.addUserNewPasswordUrl+'/'+user, password, {observe: 'response' as 'body', responseType: 'json'})
  }
  addUserGroup(user: String, group: String): Observable<HttpResponse<any>>{
   return  this.http.put<any>(this.addUserGroupUrl+'/'+user+'/'+group, null, {observe: 'response' as 'body', responseType: 'json'})
  }
  deleteUserGroup(user: String, group: String): Observable<HttpResponse<any>>{
    return  this.http.put<any>(this.deleteUserGroupUrl+'/'+user+'/'+group, null, {observe: 'response' as 'body', responseType: 'json'})
   }
   deleteUser(user: String){
    return  this.http.delete(this.deleteUserUrl+'/'+user, { observe: 'response' as 'body', responseType: 'text' })
   }
  addUserRole(user: String, role: any): Observable<HttpResponse<any>>{
   return  this.http.post<any>(this.addUserRoleUrl+'/'+user, role, {observe: 'response' as 'body', responseType: 'json'})
  }
  
  deleteUserRole(user: String, role: any): Observable<HttpResponse<any>>{
    return  this.http.post<any>(this.deleteUserRoleUrl+'/'+user, role, {observe: 'response' as 'body', responseType: 'json'})
   }
  listUsers(): Observable<User[]>{
    return this.http.get<any>(this.listUsersUrl, {observe: 'response' as 'body', responseType: 'json'}).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    )
  }

  getGroupsByUser(id:string): Observable<Group[]>{
    return this.http.get<any>(this.getGroupsByUserUrl+'/'+id, {observe: 'response' as 'body', responseType: 'json'}).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    )
  }

  getRolesByUser(id:string): Observable<Role[]>{
    return this.http.get<any>(this.getRolesByUserUrl+'/'+id, {observe: 'response' as 'body', responseType: 'json'}).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    )
  }
  login(user: {username: string, password: string}): Observable<boolean> {
    return this.http.post(this.baseUrl, user, {observe: 'response' as 'body', responseType: 'json'})
      .pipe(
        map((response: any) => {
          if(response.body == null || response.body.access_token == null || response.body.access_token == undefined){
            console.error("dt");
            return false;
          }
           this.updateWindowUser(response.body.user);
          console.info(response)
          this.doLoginUser(response.body);
          return true;
        }),
        catchError(error => {
          console.info("erreur",error.error);
         // console.error(error.error);
          return of(false);
        }),
      );
  }

  getUserByEmail(email: string): Observable<any> {
    return this.http.get<any>(this.userByEmailUrl+'/'+email, {params: {}, observe: 'response' as 'body', responseType: 'json'})
  }
  getUserById(id: string): Observable<User> {
   // return this.http.get<any>(this.getUserByIdUrl+'/'+id, {params: {}, observe: 'response' as 'body', responseType: 'json'})
    return this.http.get<any>(this.getUserByIdUrl+'/'+id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }
  getUserByUsername(email: String): Observable<any> {
    return this.http.get<any>(this.userByUsernameUrl+'/'+email, {params: {}, observe: 'response' as 'body', responseType: 'json'})
  }
  sendRequiredMail(email:any,user:String): Observable<any>{
    return this.http.put<any>(this.sendPasswordResetEmailUrl+'/'+user, email, {observe: 'response' as 'body', responseType: 'json'})
  }

  setCurrentPath(path: string) {
    sessionStorage.setItem(this.CURRENT_PATH, path);
  }

  getCurrentPath(): any {
    return sessionStorage.getItem(this.CURRENT_PATH);
  }

  /*updateWindowUser(user: Admin) {
    (window as any).__NSIA_USER__ = user;
    console.info("ld",user);
    this.userSource.next((window as any).__NSIA_USER__); 
  }*/

  updateWindowUser(user: UserInfo) {
    (window as any).__NSIA_USER__ = user;
    console.info("ld",user);
    this.userSource.next((window as any).__NSIA_USER__); 
  }

  getWindowUser() {
    return this.userSource.asObservable();
  }

  checkRouteActive(): boolean {
    this.router.events.subscribe((routerData) => {
      if (routerData instanceof RouterEvent) {
        this.setCurrentPath(routerData.url);
      }
    });
    const path = this.getUserPath().find(e => (this.router.url === '/' ? this.getCurrentPath() : this.router.url).includes(e));
    return path !== undefined;
  }

  logout() {
    return this.http.get<any>(`${this.logoutUrl}`, {
     
    }).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
       // alert(error.error);
        return of(false);
      })
    );
  }

  private doLoginUser(tokens: { access_token: string, refresh_token: string }) {
    this.storeTokens(tokens);
  }

  private doLogoutUser() {
    this.removeTokens();
  }

  getJwtToken() {
    return sessionStorage.getItem(this.JWT_TOKEN);
  }

  getRefreshToken() {
    return sessionStorage.getItem(this.REFRESH_TOKEN);
  }

  getLang() {
    const lang = localStorage.getItem(this.LANG);
    return lang ?? 'en';
  }

  getUserPath() {
    let paths: string[] = [];
    const pathsStored = localStorage.getItem(this.USER_PATH);
    (JSON.parse(pathsStored as string) as any[]).forEach(el => paths.push(ALL_PATH[el]));
    return paths;
  }

  getApiUserInfo(): Observable<any> { 
    return this.http.get<any>(environment.oauthApiUrl + `/auth/`+environment.realm+`/userinfo`,  {observe: 'body'});
  }

  getUserRoles(): Observable<any> { 
    return this.http.get<any>(environment.oauthApiUrl + `/auth/user/`+environment.realm+`/currentUserRoles`,  {observe: 'body'});
  }

  isLoggedIn() {
   // console.info(this.getJwtToken());
    if(this.getJwtToken() != null) {
      return true;
    }
    return false;
  }

  // for save user authorized path
  /*storeUserPath(paths: number[]) {
    localStorage.setItem(this.USER_PATH, JSON.stringify(paths));
  }*/
  storeUserPath(paths: string[]) {
    localStorage.setItem(this.USER_PATH, JSON.stringify(paths));
  }

  // for save token in the session
  private storeTokens(tokens: {access_token: string, refresh_token:string}) {
    sessionStorage.setItem(this.JWT_TOKEN, tokens.access_token);
    sessionStorage.setItem(this.REFRESH_TOKEN, tokens.refresh_token);
  }

  // for save lang in local storage
  storeLang(lang: string) {
    localStorage.setItem(this.LANG, lang);
    this.translate.setDefaultLang(lang);
  }

  // for save user info in the session
  storeUser(user: Admin) {
   // console.info(user)
    sessionStorage.setItem(this.USER_INFO, JSON.stringify(user));
  }

  refreshToken() {
    return this.http.post<any>(`${environment.oauthApiUrl}/auth/`+environment.realm+`/refresh_token`, {
      'refresh_token': sessionStorage.getItem(this.REFRESH_TOKEN)
    }).pipe(tap((tokens: any) => {
      console.info(tokens);
      if(tokens.access_token == null){
        this.router.navigate(['/authentication/login']);
      }
      this.storeTokens(tokens);
    }));
  }

  // for remove data in the session
  private removeTokens() {
    sessionStorage.removeItem(this.JWT_TOKEN);
    sessionStorage.removeItem(this.REFRESH_TOKEN);
    sessionStorage.removeItem(this.USER_INFO);
    delete (window as any).__BLINE_USER__;
  }

}
