import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Common } from '../config/common';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/services/alert.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,private alertService : AlertService) {
  }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private static addToken(request: HttpRequest<any>, token: string) {
    if (request.body instanceof FormData) {
      request = request.clone({ headers: request.headers.delete('Content-Type', 'application/json') });
      request = request.clone({ headers: request.headers.delete('Accept', 'application/json')});
    }
    if (request.url.includes('login') || request.url.includes('/refresh_token')) {
      return request.clone({
        setHeaders: {},
      });
    }
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      },
    });
  }
  private static addDataToStaff(request: HttpRequest<any>) {
    var senderId = localStorage.getItem("userId")
    var pathToAdd = ""+environment.realm+"/"+senderId;
    var modifiedUrl = request.url ;
    if(request.url.includes("staff-companiesms.crm") || request.url.includes("operations.crm") || request.url.includes("productms.crm")
    || request.url.includes("souscription.crm")){
      modifiedUrl = request.url.endsWith('/') ? request.url + pathToAdd : request.url + '/' + pathToAdd;
      //console.error(request.url)
      //alert(request.url)
     
    }
    const modifiedReq = request.clone({ url: modifiedUrl });
    // Passez la requête modifiée au handler
    return modifiedReq;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.getJwtToken()) {
      request = TokenInterceptor.addToken(request, this.authService.getJwtToken());
    }
    request = TokenInterceptor.addDataToStaff(request);
    //console.log("lelog",request)
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
       // console.info("token "+sessionStorage.getItem("BLINE_REFRESH_TOKEN"))
        return this.handle401Error(request, next);
      } else {
this.alertService.showAlert(error,request.method);
        return Common.handleError(error);
      }
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((tokens: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(tokens.token);
          return next.handle(TokenInterceptor.addToken(request, tokens.token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(tokens => tokens != null),
        take(1),
        switchMap(tokens => {
          return next.handle(TokenInterceptor.addToken(request, tokens.token));
        }),
      );
    }
  }
}
