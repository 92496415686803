import { HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Observer } from "./pattern_observer/observer";
import { environment } from "src/environments/environment";


export abstract class WebsocketAbstractClass {

  ws = new WebSocket('wss://'+environment.ws);
  subscription: any;

  observers: Observer[] = [];

 public addObserver(object: Observer) {
    let index = this.observers.indexOf(object as any);
    //console.log(index);
    if (index==-1) {
          this.observers.push(object)
    }
  }

  deleteObserver(object:any) {
    let index = this.observers.indexOf(object)
    this.observers.splice(index, 1)
  }

  notifyAll(data:any) {

    this.observers.forEach((ob,i) => {
      console.log("observer",ob,"observer index",i);

      ob.updateData(data);
    })
  }

  abstract send(meta:any, room:any, msg:any, guid?:any):any;
  abstract sendMessage(meta:any, room:any, msg:any, guid?:any):any;
  abstract joinRoom(room:any, guid:any):any
  abstract leaveRoom(room:any, guid:any): void;

  abstract connect():any
  abstract disconnect():any
  abstract subscribe():any
  abstract unsubscribe():any


}
