export const ALL_PATH: any[] = [
 /* '/authentication',
  '/dashboard',
  '/admin',
  '/client-provider',
  '/service-management',
  '/transaction',
  '/promotion',
  '/withdraw',
  '/country-setting',
  '/payment-method',
  '/diplome',
  '/ticket-system',
  '/prix'*/
];

export const ROLE_SUPER_ADMIN_PATH: number[] = [/*0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12*/];

export const ROLE_MANAGER_PATH: number[] = [/*0, 3, 5, 6, 7*/];

export const ROLE_ADVISOR_PATH: number[] = [/*0, 5*/];

export const ROLE_MARKETING_PATH: number[] = [/*0, 3, 6*/];

export const ROLE_SUPPORT_PATH: number[] = [/*0, 3*/];




