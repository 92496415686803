import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from '../environments/environment';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Collaborateur, Entreprise, Equipe, ObjectifSuccursale, Poste, Produit, ProduitCategorie, Prospect, Reseau, Succursale, Ville, collaborateurEquipeRelation } from "src/models/all-interfaces";

@Injectable()
export class OperationService {
  private baseUrl: string = environment.operationsApi;

  private userSource: BehaviorSubject<any>;
  constructor(private http: HttpClient, private translate: TranslateService, private router: Router) {

  }

  /*addSuccursale(): Observable<Succursale[]> {
      return this.http.get<any>(this.baseUrl + '/query/succursales', { observe: 'response' as 'body', responseType: 'json' }).pipe(
          catchError(error => {
              console.error(error);
              return of(null);
          }),
          map((response: any) => {
              return response.body;
          })
      )
  }*/



  listReseaux(): Observable<Reseau[]> {
    return this.http.get<any>(this.baseUrl + '/query/reseaux', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }
  listEquipes(): Observable<Equipe[]> {
    return this.http.get<any>(this.baseUrl + '/query/equipes', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }



  uploadFile(data: any, id: String, entity: String, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/' + entity + '/uploadFile/' + id + '/' + roomId, data, { observe: 'response' as 'body', responseType: 'text' });
  }


  addCollaborateurEquipe(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/collaborateurEquipes/' , data, { observe: 'response' as 'body', responseType: 'text' })
  }
  updateEquipe(roomId: string,data: any) {
    return this.http.put(this.baseUrl + '/command/equipes/' , data, { observe:'response' as 'body', responseType: 'text' })
  }

  


  getEntreprises(): Observable<Entreprise[]> {
    return this.http.get<any>(this.baseUrl + '/query/entreprises/', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(error => {
        console.error(error);
        return of(null);
      })
    );
  }


  addEquipe(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/equipes/', data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addReseau(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/reseaux/', data, { observe: 'response' as 'body', responseType: 'text' })
  }

  deleteReseau(id:any,roomId:String){ 
    return this.http.delete(this.baseUrl + '/command/reseaux/' + id, { observe: 'response' as 'body', responseType: 'text' });
  }

  deleteEquipe(id:any,roomId:String){ 
    return this.http.delete(this.baseUrl + '/command/equipes/' + id, { observe: 'response' as 'body', responseType: 'text' });
  }

  deleteCollaborateur(id:any,roomId:String){ 
    return this.http.delete(this.baseUrl + '/command/collaborateurs/' + id, { observe: 'response' as 'body', responseType: 'text' });
  }

  deleteCollaborateurEquipe(id:any,roomId:any) { 
    return this.http.delete(this.baseUrl + '/command/collaborateurEquipes/' + id, { observe: 'response' as 'body', responseType: 'text' });
  }

  

  updateReseau(data: any, roomId: String): Observable<any> {
    return this.http.put(this.baseUrl + '/command/reseaux/' , data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addObjectifSuccursale(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/objectifSuccursales/' , data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addObjectifReseau(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/objectifReseaux/' , data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addObjectifEquipe(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/objectifEquipes/' , data, { observe: 'response' as 'body', responseType: 'text' })
  }
  addObjectifCollaborateur(data: any, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/objectifCollaborateurs/' , data, { observe: 'response' as 'body', responseType: 'text' })
  }

  getObjectifSuccursale(roomId: String): Observable<ObjectifSuccursale[]> { 
    return this.http.get<any>(this.baseUrl + '/query/objectifSuccursales', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  getCollaborateurEquipeRelation(collabId: String,equipeId: String): Observable<collaborateurEquipeRelation> {
    return this.http.get<any>(this.baseUrl + '/query/collaborateurEquipes/getByCollaborateurIdAndEquipeIdQuery/'+collabId+'/'+equipeId, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  getEquipeCollaborateurById(id: String): Observable<Collaborateur[]> {
    return this.http.get<any>(this.baseUrl + '/query/collaborateurEquipes/getCollaborateursByEquipeId/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  getReseauById(id: String): Observable<Reseau> {
    return this.http.get<any>(this.baseUrl + '/query/reseaux/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  getEquipeById(id: String): Observable<Equipe> {
    return this.http.get<any>(this.baseUrl + '/query/equipes/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }
  getObjectifReseau(roomId: String): Observable<ObjectifSuccursale[]> {
    return this.http.get<any>(this.baseUrl + '/query/objectifReseaux', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }
  getObjectifEquipe(roomId: String): Observable<ObjectifSuccursale[]> {
    return this.http.get<any>(this.baseUrl + '/query/objectifEquipes', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }
  getObjectifEquipeById(id: String): Observable<ObjectifSuccursale[]> {
    return this.http.get<any>(this.baseUrl + '/query/objectifEquipes/ByEquipe/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  getObjectifCollaborateurById(id: String): Observable<ObjectifSuccursale[]> {
    return this.http.get<any>(this.baseUrl + '/query/objectifCollaborateurs/ByCollaborateurId/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  getObjectifReseauById(id: String): Observable<ObjectifSuccursale[]> {
    return this.http.get<any>(this.baseUrl + '/query/objectifReseaux/ByReseau/' + id, { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }
  getObjectifCollaborateur(roomId: String): Observable<ObjectifSuccursale[]> {
    return this.http.get<any>(this.baseUrl + '/query/objectifCollaborateurs', { observe: 'response' as 'body', responseType: 'json' }).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      }),
      map((response: any) => {
        return response.body;
      })
    )
  }

  getEntityFileUrl(entity: String, fileName: String): string {
    console.log(fileName)
    if (fileName == null || fileName == undefined || fileName == "" || fileName == "null" || fileName == "string" || fileName.indexOf("/data/user") >= 0) {
      if (entity != "reseaux" && entity != "equipes") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
      } else {
        return "assets/images/new/network.avif";
      }
    }
   // return this.baseUrl + '/command/' + entity + '/files/' + fileName
   return fileName.toString();
  }
  uploadEquipeFile(data: any, id: String, roomId: String): Observable<any> {
    return this.http.post(this.baseUrl + '/command/equipes/uploadFile/' + id + '/' + roomId, data, { observe: 'response' as 'body', responseType: 'text' });
  }
}